<template>
  <builder-chart v-bind="{ ...props, data, nxChartOptions, chartOptions }">
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </builder-chart>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { bar as api } from '../composables/builderOptions'
import { BuilderProps } from '../composables/builderComponent'
import { bar } from './stories'
import useTranslations from '../composables/translations'

const viz = 'bar'
const props = defineProps<BuilderProps>()
const { translateData } = useTranslations(props)

const componentVersion = {
  v1: (horizontal: boolean) => (horizontal ? 'nx' : 'dx'),
  v2: () => 'nx',
}

const nxChartOptions = computed(() => {
  const version = props.options.version || 'v1'
  const horizontal = props.options.horizontal
  const lib = componentVersion[version] ? componentVersion[version](horizontal) : 'nx'
  if (version === 'v2') {
    return {
      viz,
      lib,
      legendOptions: { position: 'bottom' },
      version,
      limit: null,
      other: false,
    }
  }
  return {
    viz,
    lib,
    legendOptions: { position: 'bottom' },
    version,
  }
})
function parseLegendPosition(position?: string) {
  if (!position || position === 'default') return 'bottom'
  if (position === 'floating') return 'absolute'
  return position
}

const chartOptions = computed(() => {
  return {
    legendOptions: {
      position: parseLegendPosition(props.options.legendPosition),
      shape: props.options?.legendShape || 'square',
      orientation: props.options?.legendOrientation || 'horizontal',
    },
  }
})
</script>

<script lang="ts">
export default {
  api,
  viz: 'bar',
  styles: {
    'bar-value': `font-weight: bold;`,
    'bar-label': '',
    'bar-bar': {
      name: 'Bar',
      css: ``,
    },
    'bar-group': {
      name: 'Bar group',
      css: ``,
    },
    '.builder-bar .legend-value': {
      name: 'Legend Value',
      css: '',
    },
    '.builder-bar .legend-label': {
      name: 'Legend Label',
      css: '',
    },
    '.builder-bar .nx-chart-legend': {
      name: 'Legend',
      css: '',
    },
  },
  story: bar,
}
</script>
